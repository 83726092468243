ul, ol {
	margin: 0 0 1.5em 1.5em;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 1.5em 1.5em;
}

.post--content .rangeFilterLabels,
.rangeFilterLabels{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 10px auto 0;

  li{
    background-image: none;
    margin: 0;
    text-indent: 0;
    text-align: center;
    width: 110px;

    @include media-max(768px){
      min-width: 44px;
      width: 14%;
    }
  }
}
