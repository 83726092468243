.widget {
	margin: 0 0 1.5em;

	/* Make sure select elements fit in widgets. */
	select {
		max-width: 100%;
	}
}

/* Search widget. */
.widget_search .search-submit {
}
