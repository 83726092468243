/**
 * General Layout styles
 */

.sidebar {
    padding: 10px;
}

/* sidebar widgets 
aside {
    max-width: (100%/3);
    @include media-min(992px) {
        max-width: 100%;
    }
}
*/

.layout--divider {
    width: 100%;
    display: block;
    margin-top: 0;
    @include media-min(768px){        
        margin-top: 65px;
    }
    @include media-min(991px){        
        margin-top: 0;
    }
}