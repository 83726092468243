/**
 *
 * Content styles
 *
 */

h1, h2, h3, h4, h4, h6 {
  &.content--subtitle {
    margin-bottom: 15px;
    margin-top: 15px;
    color: $redLight;
  }
}

h1, h2, h3, h4, h4, h6 {
  &.subtitle--red {
    margin-bottom: 15px;
    margin-top: 15px;
    color: $redLight;
  }
}

h1, h2, h3, h4, h4, h6 {
  &.subtitle--brown {
    margin-bottom: 15px;
    margin-top: 15px;
    color: $brown;
  }
}

h1, h2, h3, h4, h4, h6 {
  &.subtitle--gray {
    margin-bottom: 15px;
    margin-top: 15px;
    color: $blackLight;
  }
}

/* --- post content --- */
.post--content {

  ul {
    padding: 0;
  }
  li {
    //padding:0.15em 0 0.5em 1.5em;
    margin-bottom: 3px;
    text-indent: 15px;
    list-style: none;
    bgr: no-repeat;
    bgp: left 4px;
    bgi: url($liArrow);
  }

  img {
    display: block;
    margin: 10px auto;
  }

}

.post--container {
  margin-left: 15px;
  margin-right: 15px;
}

/* --- font --- */

.font--smaller {
  fs: 0.85em;
}

/* --- clean anchors --- */
.clean-a:link,
.clean-a:visited,
.clean-a:hover,
.clean-a:focus {
  color: inherit;
  text-decoration: none;
}

$main-font-family: 'Overpass';

$types: (700, bold, normal),
        (700, bold, italic),
        (400, regular, normal),
        (400, italic, italic),
        (300, light, normal),
        (300, light_italic, italic),
        (100, extra_light, normal),
        (100, extra_light, italic);

@each $weight, $name, $style in $types {
  @font-face {
    font-family: $main-font-family;
    src: url(fonts/overpass/Overpass_#{$name}.ttf);
    font-weight: $weight;
    font-style: $style;
  }
}

* {
  font-family: $main-font-family, sans-serif;
}

/**
 * Range slider
 */
#rangeFilter {

  .noUi-base {
    .noUi-connect {
      background-color: $camel;
    }
    .noUi-handle {
      border-color: $redDark;
      background-color: $red;
      box-shadow: 0 0 1px $red inset, 0 1px 7px $redLight inset, 0 3px 6px -3px $redDark;
      cursor: pointer;
    }
  }

  &[disabled] {
    .noUi-connect {
      background-color: $grayDark;
    }
    .noUi-handle {
      border-color: $grayDark;
      background-color: $gray;
      box-shadow: 0 0 1px $gray inset, 0 1px 7px $grayLight inset, 0 3px 6px -3px $grayDark;
      cursor: pointer;

      &:before,
      &:after {
        background-color: $blackLight;
      }
    }
  }
}

/**
 * Properties List
 */
.searchPropertyLoader {
  display: none;

  img {
    margin: 20px auto;
    max-width: 60px;
  }
}

#searchPropertyMap {
  overflow: hidden;
  transition: padding 500ms linear 0s;

  #infoContent {
    display: flex;
    flex-flow: row wrap;

    .description {
      padding: 0 0 0 10px;
    }

    p {
      margin: 0 auto 2px;

      span {
        display: block;
        font-weight: bold;
      }
    }
    img {
      margin: 0;
      max-width: 200px;
    }
    a,
    a:visited,
    a:hover,
    a:active {
      color: #FFFFFF;
    }
  }
}

#rangeFilter {
  margin: 0 auto;
  width: 86%;
}

.searchPropertyContainer {

  .searchPropertyBox {
    .img-thumbnail {
      max-width: 100%;
      width: 100%;
    }
  }

  &.gridLayout {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .searchPropertyBox {
      margin-bottom: 30px;
      width: 30%;

      .propertyFeedItem {
        width: 100%;

        .renglon {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          .subtitle--red {
            margin-bottom: 0;
            margin-top: 5px;
            min-height: 2.2em;

            &.price {
              margin-bottom: 10px;
              min-height: 1.1em;
              width: 100%
            }
            a,
            a:hover,
            a:visited {
              color: $redLight;
            }
          }

          a:hover,
          a:visited {
            color: #ffffff;
          }

          .details--adress,
          .details--suites {
            margin: 0 auto;
            width: 100%;
          }
        }
      }
    }
  }
}

/**
 * Single Properties
 */
.single-prop {

  .entry-header {
    .entry-title {
      color: $black;
      font: {
        size: 24px;
        weight: 300;
      }
    }
  }

  .post--content {
    margin: 0 auto;

    h3 {
      &.subtitle--red {
        width: 75%;
      }
    }

    label {
      color: $color__text-input;
      font: {
        size: 13px;
        weight: 400;
      }
      text-transform: uppercase;
    }

    .img-thumbnail {
      max-width: 100%;
      width: 100%;
    }

    .resume-info {
      p {
        color: $black;
        font: {
          size: 18px;
          weight: 400;
        }

        &.availability {
          display: inline-block;
        }
        &.amount {
          color: $color__text-red;
          font: {
            size: 21px;
            weight: 700;
          }
        }
      }
    }

    .tableResume {

      .renglon {

        > div {
          display: inline-block;
        }

        label {
          margin: 0;
        }

        p {
          color: $black;
          display: inline-block;
          font: {
            size: 16px;
            weight: 300;
          }
          margin: 0 0 0 5px;

          .btn-mi {
            padding: 4px 10px;

            &:visited,
            &:link {
              color: inherit;
            }
          }
        }
      }
    }

    .propertyFeedItem--extraInfo {
      font-size: 16px;
      font-weight: 300;

      p {
        &.legend {
          color: $color__text-input;
          font: {
            size: 13px;
            weight: 300;
          }

          span {
            color: $red;
            font: {
              weight: 700;
            }
          }
        }
      }

      .propertyFeedItem--menu {
        .btn {
          @include media-max(1200px) {
            margin-bottom: 10px;
          }
        }
      }

      .btn-requirements {
        align-self: flex-end;
        margin-left: auto;

        @include media-max(1200px) {
          margin-left: 0;
        }
        @include media-max(480px) {
          align-self: flex-start;
        }
      }

      .btn-backToTop {
        align-self: flex-end;
        margin: 0 auto;
      }

      .extraBlock {
        text-align: center;

        h3 {
          color: $color__text-red;
          font: {
            size: 21px;
            weight: 400;
          }
          margin: 20px auto;
        }

        h5 {
          color: $color__text-input;
          font: {
            size: 14px;
            weight: 400;
          }
          margin: 0 auto 20px;
        }

        .extraContent {
          margin: 0 auto 20px;
        }

        #propertySlider {
          .slick-slide {
            height: auto;

            img {
/*              height: 100%;*/
              max-width: 100%;
            }
          }

          .slick-prev::before,
          .slick-next::before {
            color: $red;
          }
        }

        .btn-backToTop {
          margin: 20px auto;

          &:visited,
          &:link {
            color: inherit;
          }
        }
      }
    }
  }

}

#spContactLb {
  display: none;
  max-width: 350px;

  figure {
    img {
      width: 100%;
    }
  }
}

#spSimilaresLb {
  display: none;
}

@media screen and (max-width: 600px) {
  .propertyFeedItem--heading.box--center-between {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
  }
  .subtitle--red {
    margin: 3px 0 !important;
    &:last-child {
      margin-bottom: 15px !important;
    }
  }
  .queryFilters {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    > * {
      margin: 5px 0;
    }
  }
  input[type=checkbox], input[type=radio] {
    transform: scale(1.3)
  }
}
