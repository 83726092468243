$color__background-body: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__link: royalblue;
$color__link-visited: purple;
$color__link-hover: midnightblue;
$color__text-main: #404040;
$color__text-red: #b32f21;

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;


// Paleta

$brown: #62361d;
$brownDark: #4e2b17;
$brownLight: #724a34;

$red: #b42310;
$redDark: #901c0d;
$redLight: #bc3928;

$orange: #ffac00;
$orangeDark: #cc8a00;
$orangeLight: #ffc448;

$yellow: #fbdf00;
$yellowDark: #c9b200;
$yellowLight: #fce848;

$black: #303122;
$blackDark: #26271b;
$blackLight: #454639;


$camel2: #cacabe;

$camel: #9a9477;
$camelDark: #7b765f;
$camelLight: #a49f85;

$gray: #dadcd6;
$grayDark: #aeb0ab;
$grayLight: #dee0da;

$soft: #efefed;
$softDark: #bfbfbe;
$softLight: #f1f1ef;

$softer: #f6f6f5;
$softerDark: #c5c5c4;
$softerLight: #f7f7f6;

$white: #ffffff;
$whiteDark: #cccccc;

/* --- social networks --- */

$facebook: #4060a5;
$google: #dc473a;
$twitter: #00abe3;
$youtube: #de332c;
$linkedin: #0094bc;
$pinterest: #cb2027;
$instagram: #5280a4;

$networks:  (facebook, $facebook),
            (google-plus, $google),
            (twitter, $twitter),
            (youtube-play, $youtube),
            (linkedin, $linkedin),
            (pinterest-p, $pinterest),
            (instagram, $instagram);

@each $name, $color in $networks {
    .#{$name} {
        color: $color;
    }
}

/* --- color clases --- */
.white {
    color: $white;
}
