/**
 * Elements styles
 */

/* --- search box --- */
.searchBox {
    &--div {
        border: 1px solid $softDark;
        br: 5px;
        width: 265px;
        margin: 10px;
        padding: 15px;
        bgc: $orange;
    }

    &--img {
        bgi: url('img/red-arrow-down.png');
        width: 53px;
        height: 53px;
    }

    &--txt{
        width: 115px;
        fw: 600;
    }
}

/* --- sidebarMenu --- */
.sidebarMenu {
    box-sizing: border-box;
    &--container{
        br: 5px;
        margin: 10px;
        border: 1px solid $gray;
        width: 265px;
        .sidebarMenu--list {
            margin: 0;
            padding: 0;
            bgc: $soft;
            @include media-max(992px){
                display: none;
            }
        }

        @include media-max(992px){
            @include openCaret($camel, '.sidebarMenu--title');
            @include closeCaret($camel, '.sidebarMenu--title.opened');
        }

        .sidebarMenu--item:last-child{
            border-bottom: none;
        }

        .menu-item,
        .sidebarMenu--item {
            @include cleanA();
            fs: 15px;
            list-style-type: none;
            padding: 10px;
            border-bottom: 1px dotted $gray;

            a {
                display: block;
                margin-left: 20px;
            }
        }
        @include liCaret($camel, '.menu-item a');
        @include liCaret($camel, '.sidebarMenu--item a');

        .sidebarMenu--title:first-child{
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-top: none;
        }
        .sidebarMenu--title {
            fs: 15px;
            padding: 10px;
            border-bottom: 1px solid $gray;
            border-top: 1px solid $gray;
            bgc: $softer;
            span {
                fw: bold;
            }
        }
    }
}

/* --- Boxes --- */

.miBox {
    bgc: $soft;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    br: 5px;
    height: 270px;
    text-align: center;

    h2{
      color: $blackLight;
      font-size: 20px;
      line-height: 22px;
      margin: 0 auto;
      min-height: 66px;
      text-decoration: none;
    }

    a{
      text-align: center;
    }

    &--img{
        width: 148px;
        height: 148px;
        bgs: cover;
        bgp: center center;
        margin: 10px auto;
    }
    p {
        ta: center;
    }

}

/* --- hr --- */

hr.solid-camel {
    @include hr(2px, solid, $camelLight);
}

hr.thin-camel {
    @include hr(1px, solid, $camelLight);
}

hr.dotted-camel {
    @include hr(1px, dotted, $camelLight);
}

hr.dots-gray {
    @include hr(1px, dotted, $gray);
}

hr.dots-gray-short {
    @include hr(1px, dotted, $gray, 130px);
}


/* --- Contact form --- */
.contactForm{
    &--group{
        width: 100%;
        textarea, input{
            border: 1px solid $camel;
            br: 5px;
            width: 100%;
            bgc: $camel2;
        }
        input {
            height: 42px;
        }
        input[type="submit"],
        input[type="button"]{
            @extend .btn-mi-red;
        }
        label {
            margin-bottom: 3px;
            fw: 400;
        }
    }
}

/* --- F.A.Q. --- */
/*

.faq--container
.opened
.faq--topic-heading
.faq--topic-content

.faq--topic-content
.opened
.faq--question-heading
.faq--question-content
*/

.faq {

    &--topic {
        &-heading {
            width: 100%;
            border-bottom: 1px solid $camel;
            margin-bottom: 15px;
            margin-top: 15px;
            padding-bottom: 10px;
            padding-top: 10px;
            bgi: url($fatArrowDown);
            bgp: 1% center;
            bgr: no-repeat;
            h3 {
                margin: 5px 0;
                fs: 1.30em;
                text-indent: 40px;
            }
            &.opened {
                bgi: url($fatArrowUp);
            }
        }

        &-content {
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    &--question {
        border-bottom: 1px dashed $camel;
        &-heading {
            width: 100%;
            margin-bottom: 15px;
            margin-top: 15px;
            bgi: url($slimArrowDown);
            bgp: 1% center;
            bgr: no-repeat;
            p {
                margin: 5px 0;
                fw: bold;
                text-indent: 40px;
            }
            &.opened {
                bgi: url($slimArrowUp);
            }
        }

        &-content {
            margin-left: 40px;
            margin-bottom: 15px;
        }

    }

    &--topic-heading, &--question-heading {cursor: pointer;}
    &--topic-content, &--question-content {display: none;}
}


/* --- single properties feed --- */
.propertyFeedItem {
    &--img {
        object-fit: cover;
    }

    &--content {

    }

    &--heading{
      @include cleanA();
    }

    &--menu {
      & > div {
          margin: 5px;
      }

      a {
        &:visited,
        &:link{
          color: inherit;
        }
        &.btn-mi-red{
          color: white;
          margin-right: 5px;
        }

        &.btn-mi{
          &.enabled{
            background-color: $orange;
            color: $blackLight;

            &:hover{
              color: $red;
            }
          }
          &.disabled{
            color: $grayDark;
          }
        }
      }
    }

    &--details {
        margin: 0 0 20px;
        & > p {
            margin: 0 0 5px;
        }
        .details-- {

            &adress { fs: 18px;}
            &rooms { fs: 16px;}
            &surface { fs: 16px;}
            &state { fs: 14px;}
        }
    }
}

.rangeFilterBlock{
  &.disabled{
    cursor: progress;
    opacity: .5;
  }

  .queryFilters{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 10px auto 0;

    label {
      font-weight: 600;
    }

    .sortBy{
      label{
        margin: 0 12px 0 0;
      }
      select{
        //background-color: rgba($camel, .5);
        border: 1px solid $red;
        border-radius: 3px;
        cursor: pointer;
        font-weight: 600;
        height: 32px;
        padding: 0 5px;
      }
    }
  }
}

.properties {
  &--menu {
    @include cleanA();
    a {
      padding: 5px 10px;
      &.active {
        color: $camel;
      }

      @include media-max(1000px){
        &:nth-child(2){
          display: none;
        }
      }
    }
    &.disabled{
      a{
        cursor: progress;
        opacity: .5;
      }
    }
  }
}

.detailActions{

  @include media-max(480px){
    flex-direction: column;
  }
  > div{
    margin: 0;

    a.btn{
      margin: 0 0 10px;

      &.btn-mi-red{
        line-height: 22px;
      }
      &:last-child{
        margin: 0;
      }
    }

    &:last-child{
      @include media-max(480px){
        align-items: flex-start;
      }
    }
  }
}

/**
 * Anchor Elements
 */
.anchorElement{
  position: relative;
  top: -106px;
}


/**
 * Fancybox styles
 */
.fancybox-skin{
  .fancybox-close{
    background-color: $red;
    background-image: none;
    border: 1px solid $redDark;
    border-radius: 6px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    height: 30px;
    line-height: 28px;
    right: 10px;
    text-align: center;
    top: 10px;
    width: 30px;

    &:hover{
      text-decoration: none;
    }
  }

  figcaption{
    background-color: $camelLight;
    margin: 0 auto 10px;
    padding: 2px 5px;
  }

  input{
    height: 30px;
  }

  textarea{
    height: 80px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea{
    background-color: #cacabe;
    border: 1px solid #9a9477;
    border-radius: 5px;
    width: 100%;
  }

  input[type="submit"]{
    background-color: #b42310;
    border: medium none;
    border-radius: 5px;
    color: #fff;
    outline: medium none;
    padding: 0 10px;
  }
}


.wpcf7-checkbox {
  input {
    vertical-align: middle;
    margin-right: 10px;
  }
  .wpcf7-list-item-label {
    vertical-align: middle;
  }
}