/**
* Footer style
*/

footer {
  width: 100%;
  bgc: #2f2f2f;
  color: $grayDark;
  margin-top: 10px;
  padding-top: 20px;
  @include cleanA();
  ul {
    margin: 0.75em 0;
    padding: 0 1em;
    list-style: none;
  }
  /* --- footer list bullets --- */
  @include liCaret();
}

.footer--brand {
  bgc: #202020;
  width: 100%;
  margin-top: 10px;
  padding: 25px;
}

.footer--widget {
  fs: 13px;
  &-title {
    fs: 14px;
    fw: bold;
    color: $white;
  }
}


.footer--widget-contact {
  color: $grayDark;
  i {
    color: $camelDark;
  }
}

.social--icon{
  margin: 5px 3px;
  cursor: pointer;
  color: white;
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: $white;
  }

  &-bar {
    width: 100%;
    @include media(768px){
      width: 70%;
    }
  }
}

.tagcloud {
  a{
    &:hover {
      color: $camelDark;
    }
  }
}