/**
 * Customization to Bootstrap styles
 */
 
/* --- Contextual backgrounds --- */
 
.bg-error {
    @extend .bg-danger;
    padding: 15px;
    border: 1px solid rgba($redLight, 0.5);
    br: 3px;
    color: $black;
}

/* --- Navbar --- */
.navbar-default {
    bgc: $gray;
    fs: 12px;
}

.nav > li > a {
    fs: 14px;
    fw: 400;
    padding: 15px 10px;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    bgc: inherit;
    fw: 700;
}

.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand {
    margin-left: 10px;
    @include media-max(767px){
        margin-left: 45px;
    }
}

.nav.navbar-nav.navbar-right {
    @include media(767px){
        margin-top: 13px;
    }
}

/* --- footer hr --- */
hr { 
    @include hr(1px, dotted, $blackDark);
}    

/* --- thumbnail --- */

.img-thumbnail {
    border-radius: 0;
}
.thumbnail {
    margin: 0 10px 10px;
    border-radius: 0;
    a {
        img {
            width: 100%;
        }
    }
}

.btn-mi-gray {
    bgc: $gray;
}

.btn-mi-red{
    bgc: $red;
    color: $white;
    border: none;
    outline: none;
    &:focus,
    &:hover{
        bgc: $redDark;
        color: $white;
        border: none;
        outline: none;
    }
}