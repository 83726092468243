//* --- li caret --- */
@mixin liCaret($color: $blackDark, $selector: 'li') {
    #{$selector}:before {
        content: "";
        border-color: transparent $color;
        border-style: solid;
        border-width: 0.35em 0 0.35em 0.45em;
        height: 0;
        width: 0;
        display: block;
        top: 0.9em;
        left: -1em;
        position: relative;
    }
}


@mixin openCaret($color: $blackDark, $selector: 'li') {
    #{$selector}:after {
        content: "";
        height: 0;
        width: 0;
        border-left: .4em solid transparent;
        border-right: .4em solid transparent;
        border-top: .4em solid $color;
        border-bottom: none;
        display: block;
        right: 1em;
        position: relative;
    }
}

@mixin closeCaret($color: $blackDark, $selector: 'li') {
    #{$selector}:after {
        content: "";
        height: 0;
        width: 0;
        border-left: .4em solid transparent;
        border-right: .4em solid transparent;
        border-bottom: .4em solid $color;
        border-top: none;
        display: block;
        right: 1em;
        position: relative;
    }
}

//* --- overide a tags styles --- */
@mixin cleanA(){
    a{
        color: inherit;
        &:focus,
        &:link,
        &:hover,
        &:visited {
            color: inherit;
            text-decoration: none;
        }
        &:hover{
            cursor: pointer;
        }
    }
}

//* --- cross icon --- */ 
@mixin cross($color: white){
    .cross:before,
    .cross:after {
        cursor: pointer;
        border-radius: 1px;
        height: 5px;
        width: 35px;
        background: $color;
        position: relative;
        display: block;
        content: '';
    }

    .cross:before {
        top: 0;
        transform: rotate(45deg);
    }
    .cross:after {
        top: -5px;
        transform: rotate(-45deg); 
    }
}


//* --- plus icon --- */ 
@mixin plus($color: white, $width: 35px){
    .plus:before,
    .plus:after {
        cursor: pointer;
        border-radius: 1px;
        height: 5px;
        width: $width;
        background: $color;
        position: relative;
        display: block;
        content: '';
    }

    .plus:before {
        top: 0;
        transform: rotate(90deg);
    }
    .plus:after {
        top: -5px; 
    }
}

/**
 * <hr> generator
 */
@mixin hr($height: 1px, $style: solid, $color: black, $width: 100% ){
    display: block;
    margin: 10px 0;
    width: #{$width};
    border: 0;
    border-top: #{$height} #{$style} #{$color};
    background-color: transparent;
}