/**
 * Post/Pages general styles
 */

.entry-title {
    color: $redLight;
}

.entry--subtitle {
    font-size: responsive;
}

.home--loop {
    margin: 10px;
    @include cleanA();
}

/**
 * 404 Styles
 */
.error404{

  .page-content{
    text-align: center;
    padding: 60px 0 120px;

    figure{
      margin: 0 auto 50px;
    }

    h2{
      color: $color__text-red;
      font :{
        size: 36px;
      }
    }

    p{
      color: $black;
      font :{
        family: 'Overpass', sans-serif;
        size: 24px;
        weight: 300;
      }
    }

  }
}
