// header styles

// fix for the fixed navbar showing on top of content
/*
*/
.fixed-nav-fix {
  display: block;
  width: 100%;
}


/* --- home image --- */
.header--banner {
    width: 100%;
    height: 320px;
    bgs: cover;
    bgp: center center;
}

.header--banner-home {
    @extend .header--banner;
    bgp: center center;
    @include media-max(768px){
        display: none;
    }
}

/* --- Nav Bar --- */

.header--logo {
    bgi: url('img/logo.png');
    width: 158px;
    height: 40px;
    margin: 20px;
  @media (max-width: $screen-sm) {
    width: 130px;
    height: 30px;
    margin: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
