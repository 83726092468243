/**
 * Flexbox grid and classes
 */

// .col-x clases from 1 to 100 by 1
// .col-x { width : x% }
@for $i from 1 through 100 {
  .col-#{$i} { width: round(percentage($i/100)); }
}

/* --- x-small cols --- */
@include media-max(768px){
    @for $i from 1 through 100 {
    .col-#{$i}-xs { width: round(percentage($i/100)); }
    }
}

/* --- small cols --- */
@include media-min(768px){
    @for $i from 1 through 100 {
    .col-#{$i}-sm { width: round(percentage($i/100)); }
    }
}

/* --- medium cols --- */
@include media-min(992px){
    @for $i from 1 through 100 {
    .col-#{$i}-md { width: round(percentage($i/100)); }
    }
}

/* --- large cols --- */
@include media-min(1200px){
    @for $i from 1 through 100 {
    .col-#{$i}-lg { width: round(percentage($i/100)); }
    }
}

/* --- boxes --- */
.box--col {
  display: flex;
  flex-direction: column;
}

.box {
  display: flex;
}

.box--wrap {
  display: flex;
  flex-wrap: wrap;
}

.box--around {
  display: flex;
  justify-content: space-around;
}

.box--start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.box--start-around {
  display: flex;
  flex-direction: row;;
  align-items: flex-start;
  justify-content: space-around;
}

.box--center-start {
  display: flex;
  flex-direction: row;;
  align-items: center;
  justify-content: flex-start;
}

.box--center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.box--baseline-around {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
}

.box--wrap-center-around {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.box--wrap-center-between {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.box--wrap-around {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.box--col-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.box--col-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.box--col-center-around {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.box--col-center-between {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.box--col-start-around {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-around;
}

.box--col-end-around {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-around;
}

.box--wrap-start-start {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.box--center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box--wrap-center-start {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.box--wrap-start-around {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
}

.box--wrap-start-between {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

/**
 * Flexbox items
 */

.item--first {
    order: -1;
}

.item--last {
    order: 1;
}
